
import * as customProviderRuntime$lCLNQX11zQ from '/var/builds/t3headlessfront/production/releases/140023/macopedia-shared/helpers/imgProxyProvider.ts'

export const imageOptions = {
  "screens": {
    "xs": 400,
    "sm": 768,
    "md": 1024,
    "lg": 1360,
    "xl": 1600,
    "xxl": null,
    "2xl": 1536
  },
  "presets": {},
  "provider": "imgProxy",
  "domains": [
    "t3headless.io"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['imgProxy']: { provider: customProviderRuntime$lCLNQX11zQ, defaults: {"baseUrl":"https://t3headless.io/headless/imgproxy"} }
}
        